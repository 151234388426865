import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import {
  MDBBtn,
  MDBCol,
  MDBRow,
} from 'mdbreact';
// import { useIntercom } from 'react-use-intercom';
import connect from 'react-redux/es/connect/connect';
import Tabs from './Tabs';
import WorkOrderCard from './WorkOrderCard';
import getWorkOrders from '../../utils/api/get/getWorkOrders';
import { tabs } from '../../utils/constans';
import getWorkOrderCountByTabs from '../../utils/api/get/getWorkOrderCountByTabs';
// eslint-disable-next-line import/no-cycle
import Modal from '../modal';
import {
  setCurrentWorkOrder,
  fetchApplicantsForWorkOrder,
  publishWorkOrder,
  acceptInvite, confirmWorkOrder,
  exportWorkOrders,
  createWorkOrderTemplateByWorkOrder,
} from '../../storage/actions/workOrdersActions';
import { toggleModal, toggleSecondModalClose } from '../../storage/actions/modalsActions';
import {
  selectWorkOrder,
  setActiveTab,
  setPage,
} from '../../storage/actions/applicationStateActions';
// eslint-disable-next-line import/no-cycle
import { SocketContext } from '../../pages';
import { setMessagesCount, setMessagesSeen } from '../../storage/actions/messageCountAction';
import EstimatedTimeOverageLabel from '../layout/EstimatedTimeOverageLabel';
import PaginationPage from './Pagination';
import SelectDropDownElement from '../layout/selectDropDownElement';
import BulkElements from '../layout/bulkElements';
import prepareFilters from '../../utils/prepareFilters';

const DashboardContent = ({
  auth,
  profile,
  activeTab,
  isReload,
  match,
  history,
  search,
  filters,
  sortBy,
  selectedWorkOrders,
  selectWorkOrder,
  mainContainer,
  setActiveTab,
  toggleModal,
  setCurrentWorkOrder,
  fetchApplicantsForWorkOrder,
  setMessagesCount,
  messagesCount,
  setMessagesSeen,
  publishWorkOrder,
  acceptInvite,
  confirmWorkOrder,
  currentPage,
  perPage,
  setPage,
  exportWorkOrders,
  createWorkOrderTemplateByWorkOrder,
  toggleSecondModalClose,
}) => {
  const [workOrders, setWorkOrders] = useState([]);
  const [workOrderCountByTabs, setWorkOrderCountByTabs] = useState({});
  const { chatSocket: socket } = useContext(SocketContext);
  const [estimatedTimeOverageCount, setEstimatedTimeOverageCount] = useState(0);

  // eslint-disable-next-line consistent-return
  const fetchData = async () => {
    if (workOrders.length) {
      selectWorkOrder([]);
    }
    toggleModal(true, 'loader', true);
    const tmpFilters = prepareFilters(filters);
    getWorkOrderCountByTabs(search, tmpFilters, auth?.user?.userType, activeTab).then(response => {
      if (response.success) {
        const totalWorkOrders = response?.payload?.workOrderCountByTabs[activeTab];
        const countOfPages = Math.max(Math.ceil(totalWorkOrders / perPage), 1);
        if (countOfPages < currentPage) {
          return setPage(countOfPages);
        }
      }
      getWorkOrders(
        currentPage,
        perPage,
        activeTab,
        search,
        tmpFilters,
        { [sortBy.sortType]: sortBy.order },
        auth?.user?.userType,
      ).then(result => {
        const tmpWorkOrders = result.map(order => {
          if (order?.clientInfo?.ratings) {
            const { total, count } = order.clientInfo.ratings;
            // eslint-disable-next-line no-param-reassign
            order.clientInfo.ratings = ((total / (count * 3)) * 100).toFixed(2).replace(/\.?0+$/, '');
          }
          return order;
        });
        setWorkOrderCountByTabs(response?.payload?.workOrderCountByTabs || {});
        setWorkOrders(tmpWorkOrders);
        toggleSecondModalClose('loader', true);
      })
    });
    // const result = await 
  };

  const selectAllCheckbox = (workOrders) => {
    if (workOrders.length === selectedWorkOrders.length) {
      return selectWorkOrder([]);
    }
    return selectWorkOrder(workOrders);
  };

  const changeActiveTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      history.push(`/dashboard/${tab}`);
    }
  };

  useEffect(() => {
    const { tab } = match.params;
    if (tab === activeTab && (!profile?.status || profile?.status === 'active')) fetchData();
  },
  [
    activeTab, sortBy,
    filters, search,
    isReload, currentPage,
    perPage,
  ]);

  useEffect(() => {
    const { tab } = match.params;
    let tmpTab = '';
    if (tab) tmpTab = tabs[auth.user.userType].find(({ value }) => value === tab);
    // eslint-disable-next-line no-nested-ternary
    tmpTab = tmpTab ? tmpTab.value : auth.user.userType === 'contractor' ? 'upcoming-work' : 'assigned';
    changeActiveTab(tmpTab);
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on('newMessage', () => {
        socket.emit('receiveUnReadMessages', { workOrders: workOrders.map(el => el._id) });
      });
      socket.emit('receiveUnReadMessages', { workOrders: workOrders.map(el => el._id) });
      socket.on('unReadMessages', (data) => {
        setMessagesCount((data.reduce((acc, el) => {
          acc[el.workOrderId.toString()] = {
            unread: el.count,
            all: el.allCount,
          };
          return acc;
        }, {})));
      });
      socket.on('markAsSeen', (workOrder) => {
        setMessagesSeen({ workOrderId: workOrder })
      })
    }
  }, [workOrders]);

  useEffect(() => {
    if (
      !selectedWorkOrders.length
      && estimatedTimeOverageCount
    ) setEstimatedTimeOverageCount(false);
    if (activeTab === 'completed') {
      setEstimatedTimeOverageCount(workOrders.filter(workOrder => selectedWorkOrders
        .includes(workOrder._id)
          && workOrder.basicType === 'Hour'
          && (
            workOrder.approvedHoursQty
            > (
              workOrder.quantity
              + (workOrder.variableType === 'Hour' ? workOrder.maxQuantity : 0)
            )
          )).length);
    }
  }, [selectedWorkOrders]);

  return (
    <>
      <MDBCol md="10" className="find_apply10">
        <MDBRow className="no-margins" style={{ justifyContent: 'space-between' }}>
          <MDBCol className="no-paddings uppertwodivcdc">
            <Tabs
              style={{ display: 'flex' }}
              tabs={tabs[auth.user.userType]}
              activeTab={activeTab}
              handler={changeActiveTab}
              workOrderCountByTabs={workOrderCountByTabs}
            />
          </MDBCol>
          <BulkElements userType={auth.user.userType} workOrders={workOrders} />
          {
            (auth.user.userType !== 'contractor')
              ? (
                <>
                  <MDBCol
                    className="flex-box align-items-center flex-grow-0"
                    style={{ minWidth: 'max-content' }}
                  >
                    <SelectDropDownElement
                      isCurrentPageSelected={
                        (workOrders.length > 0)
                        && (workOrders.length === selectedWorkOrders.length)
                      }
                      selectCurrentPage={
                        () => selectAllCheckbox(workOrders.map(el => el._id))
                      }
                    />
                    {
                      activeTab === 'completed' && estimatedTimeOverageCount
                        ? (
                          <EstimatedTimeOverageLabel
                            text="Selected WO's include Time Overage!"
                            cardClassName="margin-top-20"
                          />
                        )
                        : <></>
                    }
                  </MDBCol>
                </>
              )
              : (
                activeTab === 'upcoming-work'
                || activeTab === 'completed'
                || activeTab === 'approved'
                || activeTab === 'paid'
                || activeTab === 'routed'
              ) && (
                <>
                  <MDBCol
                    className="flex-box flex-column justify-content-center flex-grow-0"
                    style={{ minWidth: 'max-content' }}
                  >
                    {
                      activeTab !== 'routed'
                        ? (
                          <MDBBtn
                            className="ee_buttcon"
                            color="secondary"
                            onClick={() => exportWorkOrders(
                              { workOrdersId: selectedWorkOrders }, auth.user.userType,
                            )}
                          >
                            Export Excel
                          </MDBBtn>
                        )
                        : <></>
                    }
                    <div className="flex-box align-items-center">
                      <SelectDropDownElement
                        isCurrentPageSelected={
                          (workOrders.length > 0)
                          && (workOrders.length === selectedWorkOrders.length)
                        }
                        selectCurrentPage={
                          () => selectAllCheckbox(workOrders.map(el => el._id))
                        }
                      />
                    </div>
                  </MDBCol>
                </>
              )
          }
        </MDBRow>
        <PaginationPage workOrderCountByTabs={workOrderCountByTabs} />
        {
          workOrders.length
            ? workOrders.map(el => (
              <WorkOrderCard
                key={el._id}
                workOrder={el}
                activeTab={activeTab}
                selectedWorkOrders={selectedWorkOrders}
                userType={auth.user.userType}
                setCurrentWorkOrder={setCurrentWorkOrder}
                fetchApplicantsForWorkOrder={fetchApplicantsForWorkOrder}
                toggleModal={toggleModal}
                messagesCount={messagesCount}
                selectWorkOrder={selectWorkOrder}
                publishWorkOrder={publishWorkOrder}
                acceptInvite={acceptInvite}
                confirmWorkOrder={confirmWorkOrder}
                createWorkOrderTemplateByWorkOrder={createWorkOrderTemplateByWorkOrder}
              />
            ))
            : null
        }
        <PaginationPage workOrderCountByTabs={workOrderCountByTabs} />
      </MDBCol>
      <Modal mainContainer={mainContainer.current} />
    </>
  );
};

const mapDispatchToProps = {
  setCurrentWorkOrder,
  fetchApplicantsForWorkOrder,
  toggleModal,
  setActiveTab,
  selectWorkOrder,
  setMessagesCount,
  setMessagesSeen,
  publishWorkOrder,
  acceptInvite,
  confirmWorkOrder,
  setPage,
  exportWorkOrders,
  createWorkOrderTemplateByWorkOrder,
  toggleSecondModalClose,
};

const mapStateToProps = state => ({
  auth: state.auth,
  messagesCount: state.messagesCount,
  ...state.applicationState,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DashboardContent));
