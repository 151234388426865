import React, { useState } from 'react';
import {
  MDBBtn, MDBCol, MDBIcon, MDBRow,
} from 'mdbreact';
import workOrderStatusControl from '../../../../utils/workOrderStatusControl';
import { localCurrencySettings } from '../../../../utils/constans';
import ContractorRating from '../../ContractorRating';
import ContractorCertificatesChecks from '../../ContractorCertificatesChecks';
import AllRequestedWorkView from './AllRequestedWorkView';
import TravelExpenses from '../CounterOfferElements/TravelExpenses';
import DateAndTime from '../CounterOfferElements/DateAndTime';
import ContractorPreferedGroupsChecks from '../../PreferedGroupsCheck';

const ApplicantCard = ({
  contractorInfo,
  setCurrentApplicants,
  toggleModal,
  currentWorkOrder,
  assignContractor,
  userType,
}) => {
  const [isShowAllWork, setIsShowAllWork] = useState(false);
  const [selectedApplies, setSelectedApplies] = useState([contractorInfo.work_order_id]);

  const workOrderControlByStatus = currentWorkOrder?.status
    ? workOrderStatusControl(currentWorkOrder.status)
    : {};

  const handleAllWorkOrderReqView = () => {
    if (isShowAllWork) setSelectedApplies([contractorInfo.work_order_id]);
    setIsShowAllWork(!isShowAllWork);
  };

  return (
    <>
      <tr>
        <td style={{ paddingLeft: '5px' }}>
          <div className="d-flex">
            <span>
              <button
                type="button"
                className="disabled-standard-button-style"
                style={{
                  fontWeight: 'bold',
                  paddingRight: '20px',
                }}
                onClick={() => {
                  toggleModal(true, 'contractorDetailsModal', false, { contractorId: contractorInfo.contractor_details.userId });
                }}
              >
                {
                  `${contractorInfo.contractor_details.name
                  } - ${contractorInfo.contractor_details.unique_id
                  }`
                }
              </button>
            </span>
          </div>
          <div className="font12">
            <div>{`${contractorInfo.contractor_details?.address} `}</div>
            <div>
              {
                contractorInfo.contractor_details?.city?.label
                  ? `${contractorInfo.contractor_details?.city?.label}, `
                  : ''
              }
              {
                contractorInfo.contractor_details?.state?.label
                  ? `${contractorInfo.contractor_details?.state?.label} `
                  : ''
              }
              {contractorInfo.contractor_details?.zipcode || ''}
            </div>
          </div>
          {
            workOrderControlByStatus.beforeAssigned
              ? (
                <div>
                  <button
                    type="button"
                    className="disabled-standard-button-style text-in-one-line"
                    style={{ color: '#6cff2e', fontWeight: 'bold' }}
                    onClick={handleAllWorkOrderReqView}
                  >
                    <MDBIcon
                      icon={isShowAllWork ? 'angle-down' : 'angle-right'}
                    />
                    <span> View all requested work</span>
                  </button>
                </div>
              )
              : <></>
          }
        </td>
        <td>
          <div className="font12">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <MDBRow>
                  <MDBCol>
                    <MDBIcon icon="phone" size="lg" className="float-left mr-2 pt-1" />
                    <a
                      href={`tel:${contractorInfo.contractor_details.phone}`}
                      style={{ color: 'white' }}
                    >
                      {contractorInfo.contractor_details.phone}
                    </a>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol style={{ paddingTop: '3px' }}>
                    <MDBIcon icon="envelope" size="lg" className="float-left pr-2 pt-1" />
                    <a
                      href={`mailto:${contractorInfo.contractor_details.email}`}
                      style={{ color: 'white' }}
                    >
                      {contractorInfo.contractor_details.email}
                    </a>
                  </MDBCol>
                </MDBRow>
              </div>
              {
                contractorInfo.contractor_details.completedWorkOrderCount
                  && contractorInfo.contractor_details.allWorkOrdersCount
                  ? (
                    <div>
                      <span style={{ fontWeight: 'bold' }}>
                        Work Performed
                      </span>
                      <div
                        title={"WO's Completed For Our Company / WO's Completed on Platform"}
                        style={{ cursor: 'pointer', textAlign: 'center' }}
                      >
                        {
                          `${contractorInfo.contractor_details.completedWorkOrderCount
                          } / ${contractorInfo.contractor_details.allWorkOrdersCount
                          }`
                        }
                      </div>
                    </div>
                  )
                  : <></>
              }
            </div>
            <MDBRow className="flex-box" style={{ paddingTop: '0.2rem' }}>
              {contractorInfo.contractor_details.groups?.length ?
                <MDBCol>
                  <ContractorPreferedGroupsChecks />
                </MDBCol> : <></>
              }
              <MDBCol>
                <ContractorCertificatesChecks
                  certificates={contractorInfo.contractor_details.certificates}
                  textAlign="center"
                />
              </MDBCol>
            </MDBRow>
            <MDBRow style={{ paddingTop: '0.2rem' }}>
              <ContractorRating contractor={contractorInfo.contractor_details} />
            </MDBRow>
          </div>
        </td>
        <td style={{ textAlign: 'right' }}>
          <MDBRow>
            {
              contractorInfo.contractor_details?.distance
                ? (
                  <MDBCol
                    className="flex-grow-0 text-in-one-line font-weight-bold"
                    style={{ fontSize: '1rem' }}
                  >
                    {`${contractorInfo.contractor_details?.distance?.toFixed(2)} Miles`}
                  </MDBCol>
                )
                : <></>
            }
            <MDBCol className="text-in-one-line">
              <div className="dollercont">
                <h5
                  style={{
                    color: '#28a745',
                    marginBottom: '-6px',
                    fontWeight: 'bold',
                  }}
                >
                  {
                    `Base Pay: ${(+contractorInfo.bidAmountBase).toLocaleString('en-US', localCurrencySettings)
                    } / ${contractorInfo.basicType ? contractorInfo.basicType : currentWorkOrder.basicType}`
                  }
                </h5>
              </div>
              <div className="dollercont">
                <h5
                  style={{
                    color: '#28a745',
                    fontWeight: 'bold',
                    marginTop: 10,
                  }}
                >
                  {
                    `Variable Pay: ${(+contractorInfo.bidAmountVariable || 0)
                      .toLocaleString('en-US', localCurrencySettings)
                    } / ${currentWorkOrder.variableType}`
                  }
                </h5>
              </div>

              <DateAndTime
                startDate={contractorInfo.startDate}
                startTime={contractorInfo.startTime}
              />
              <MDBRow style={{
                paddingTop: '0.2rem', display: 'flex', justifyContent: 'flex-end', width: '100%',
              }}
              >
                <TravelExpenses
                  amount={contractorInfo.amountExpense}
                  description={contractorInfo.descriptionExpense}
                />
              </MDBRow>
              {
                contractorInfo.comment
                  ? (
                    <div className="font12" style={{ paddingTop: '10px', whiteSpace: 'normal', wordBreak: 'break-word' }}>
                      <span style={{ fontWeight: 'bold', marginRight: '5px' }}>Comments: </span>
                      {contractorInfo.comment || ''}
                    </div>
                  )
                  : <></>
              }
            </MDBCol>
          </MDBRow>
          {
            userType === 'client'
              ? (
                <div>
                  <span>
                    {
                      <span>
                        {
                          // eslint-disable-next-line no-nested-ternary
                          workOrderControlByStatus.beforeAssigned
                            && !workOrderControlByStatus.canceled
                            ? Object.values(contractorInfo.block).some((el) => el)
                              ? (
                                <MDBBtn
                                  color="red"
                                  className="assignbutton"
                                  onClick={() => {
                                    toggleModal(true, 'blockModal', false, { contractorId: contractorInfo.contractor_id });
                                  }}
                                >
                                  Blocked
                                </MDBBtn>
                              )
                              : (
                                <MDBBtn
                                  color={contractorInfo.status === 'withdrawn' ? 'blue-grey' : 'deep-orange'}
                                  className="assignbutton"
                                  disabled={contractorInfo.status === 'withdrawn'}
                                  onClick={() => {
                                    if (contractorInfo.status !== 'withdrawn') {
                                      assignContractor(
                                        { workOrdersId: selectedApplies },
                                        contractorInfo.contractor_id,
                                      );
                                    }
                                  }}
                                >
                                  {
                                    contractorInfo.status === 'withdrawn'
                                      ? (
                                        <div>
                                          Withdrawn
                                        </div>
                                      )
                                      : (
                                        <div>
                                          Assign
                                        </div>
                                      )
                                  }
                                </MDBBtn>
                              )
                            : <></>
                        }
                      </span>
                    }
                    {
                      !['apply', 'archived'].includes(contractorInfo.status)
                      && (
                        <>
                          <MDBBtn
                            color="success"
                            className="assignbutton"
                          >
                            Assigned
                          </MDBBtn>
                          {
                            !currentWorkOrder?.checkInOutDetails?.length
                              ? (
                                <MDBBtn
                                  color="red"
                                  className="assignbutton"
                                  onClick={() => {
                                    toggleModal(true, 'unAssignModal', false, { workOrdersId: [currentWorkOrder._id] });
                                    setCurrentApplicants(contractorInfo);
                                  }}
                                >
                                  Un-Assign
                                </MDBBtn>
                              )
                              : <></>
                          }
                        </>
                      )
                    }
                  </span>
                </div>
              )
              : <></>
          }
        </td>
      </tr>
      <AllRequestedWorkView
        isShowAllWork={isShowAllWork}
        contractor={contractorInfo}
        selectedApplies={selectedApplies}
        selectApplies={setSelectedApplies}
      />
    </>
  );
};
export default ApplicantCard;
