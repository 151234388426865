import React from 'react';
import { MDBRow } from 'mdbreact';
import { useState } from 'react';
import axios from 'axios';
import Editor from '../../layout/editor';
import moment from 'moment';

const NotesSection = ({
  notes = [],
  contractorId
}) => {
  const [desc, setDesc] = useState();
  const [notesToShow, setNotesToShow] = useState(notes);

  const handleSave = async () => {
    setDesc('')
    await axios.post('/api/contractor/addNote', {
      contractorId,
      description: desc,
    }).then(res => {
      setNotesToShow(prev => [...prev, res.data.note])
    })
  }
  return (
    <div className="contprofiletools_div">
      {notesToShow.map((note, index) => (
        <MDBRow key={index} className='ml-2'>
          <span className='mr-3'>{note.createdAt && moment(note.createdAt).isValid ? moment(note.createdAt).format('MM/DD/YYYY') : ''}</span>
          <div dangerouslySetInnerHTML={{__html: 
          `${note.from}: <div class="ml-2"> ${note.description}</div>`
          }} />
        </MDBRow>
      ))}
      <label>Add new note</label>
      <Editor
        data={desc}
        onChange={desc => setDesc(desc)}
      />
      <button className='btn btn-success' onClick={() => handleSave()}>
        Save
      </button>
    </div>
  )
};

export default NotesSection;
